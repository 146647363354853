<template>
    <v-card flat class="post-card" color="grey darken-3">
        <div class="pa-1 pr-1">
            <img :src="c_valid_picture"
                 @error="valid_picture = false"
                 height="200"
                 class="mt-1 post-image"
                 @click="$emit('open_post', post.ID)"
                 alt=""
                 loading="lazy">
            <div v-if="post.deal_discount_in_percent" class="deal-banner">{{post.deal_discount_in_percent}} %</div>
        </div>
        <v-card-title class="post-title pa-3" :class="{'warning--text': post.marked_as_expired ==='1' || Number(post.count_expired) >= $variables.v.post_expiration_clicks_required_for_expired}">
            {{post.title}}
        </v-card-title>

        <v-divider></v-divider>

        <v-col cols="12">
            <post-tags :post="post"></post-tags>
        </v-col>

        <v-divider></v-divider>

        <v-col cols="12" class="disable-select">
            <miniview-user class="ml-3" v-if="hide_user !== true" :user="post"></miniview-user>
            <post-voting :class="hide_user !== true ? 'float-right mr-5' : 'ml-auto mr-auto d-table'" :post="post" :show_points="show_points" @voted="voted"></post-voting>
        </v-col>

        <v-divider></v-divider>

        <div v-if="Number(post.offer_type) === $variables.v.offer_types.deal">
            <v-row class="disable-select">
                <v-col cols="5" class="text-right">
                    <del>{{post.deal_price_original}}
                        <span v-if="post.deal_price_original">{{post.deal_currency}}</span></del>
                </v-col>
                <v-col cols="2" class="text-center">
                    <v-icon>mdi-arrow-right-bold</v-icon>
                </v-col>
                <v-col cols="5" class="text-left font-weight-bold red--text accent-1">{{post.deal_price_reduced}}
                    <span v-if="post.deal_price_original">{{post.deal_currency}}</span>
                </v-col>
            </v-row>

            <v-divider></v-divider>
        </div>


        <v-col cols="12">
            <v-row justify="center">
                <v-btn :color="$variables.v.theme.primary"
                       style="width:80%"
                       depressed
                       @click="$emit('open_post', post.ID)">
                    {{Number(post.offer_type) === $variables.v.offer_types.giveaway ? $t('post.check-giveaway') : $t('post.check-deal')}}
                </v-btn>
            </v-row>
        </v-col>
    </v-card>
</template>

<script>
    export default {
        props   : ['post', 'show_points', 'hide_user'],
        data() {
            return {
                valid_picture: true,
            }
        },
        computed: {
            c_valid_picture() {
                return this.valid_picture ? this.post.picture_url : this.$variables.v.post_defaut_picture;
            }
        },
        methods : {
            voted(action, points) {
                this.$emit("voted", this.post.ID, action, points);
            },
        },
    }
</script>